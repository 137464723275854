import Web3 from "web3";
import Store from "@/store";

const sigUtil = require("eth-sig-util");
const ethUtils = require("ethereumjs-util");

import ERC721ABI from "../utils/ERC721ABI";
import MarketABI from "../utils/MarketABI";
import SubAbi from "../utils/SubAbi";

let prKey = "";

const subContractAddress = "0x7750497d2E20b213E08311f5881e59bcDF7011A6";
const erc721MetaContractAddress = "0x11A0A05550d2002fa781e8860ac3abc8E856b201";
const marketContractAddress = "0x0741F7553636237dc126de9d55d07449696B7c16";
const paymasterContractAddress = "0x8d08afc476cc695f5f6f920034ca1ec082d7ff6a";
const systemAddress = "0x5dd5f1051b4f54bDEB9f0F8aF5d0350f18EFe437";

let web3 = null;
let subContract = null;
let _name = "";
let _nonce = "";
let _address = "";

const formatPrice = (price) => {
  return Math.round(price * 1e18).toLocaleString("fullwide", {
    useGrouping: false,
  });
};

const nonces = () => {
  return new Promise((resolve, reject) => {
    subContract.nonces(_address).call(
      {
        from: _address,
      },
      (err, res) => {
        if (err) {
          reject(err);
        }
        resolve(res);
      }
    );
  });
};

const callContract = (contract, method, param) => {
  return new Promise((resolve, reject) => {
    if (param) {
      contract[method](param).call(
        {
          from: _address,
        },
        (err, res) => {
          if (err) {
            reject(err);
          }
          resolve(res);
        }
      );
    } else {
      contract[`${method}`]().call(
        {
          from: _address,
        },
        (err, res) => {
          if (err) {
            reject(err);
          }
          resolve(res);
        }
      );
    }
  });
};

export default {
  async initWeb3() {
    prKey = Store.getters["wallet/getWallet"]?.privateKey;
    web3 = new Web3(
      new Web3.providers.HttpProvider(
        "https://polygon-mumbai.g.alchemy.com/v2/36ONIAg2z-YZlt296tyFBB1_yTyA420E"
      )
    );
    subContract = new web3.eth.Contract(SubAbi, subContractAddress).methods;
  },

  async sign(amount) {
    _address = web3.eth.accounts.privateKeyToAccount(prKey).address;
    _name = await callContract(subContract, "name");
    _nonce = await nonces();

    const EIP712Domain = [
      { name: "name", type: "string" },
      { name: "version", type: "string" },
      { name: "chainId", type: "uint256" },
      { name: "verifyingContract", type: "address" },
    ];

    const Permit = [
      { name: "owner", type: "address" },
      { name: "spender", type: "address" },
      { name: "value", type: "uint256" },
      { name: "nonce", type: "uint256" },
      { name: "deadline", type: "uint256" },
    ];

    let domainData = {
      name: _name,
      version: "1",
      chainId:
        "0x0000000000000000000000000000000000000000000000000000000000013881",
      verifyingContract: subContractAddress,
    };

    let message = {
      owner: _address,
      spender: paymasterContractAddress,
      value: formatPrice(amount),
      nonce: parseInt(_nonce),
      deadline: 9999999999,
    };

    const dataToSign = {
      types: {
        EIP712Domain: EIP712Domain,
        Permit: Permit,
      },

      domain: domainData,
      primaryType: "Permit",
      message: message,
    };

    return sigUtil.signTypedMessage(ethUtils.toBuffer(prKey), {
      data: dataToSign,
    });
  },

  async sellCsh(tokenId, proposalPrice) {
    try {
      const metaTxStorage = [];
      const erc721MetaContract = new web3.eth.Contract(
        ERC721ABI,
        erc721MetaContractAddress
      ).methods;

      const marketContract = new web3.eth.Contract(
        MarketABI,
        marketContractAddress
      ).methods;

      let domainData = await this.getDomainDataByContract(
        erc721MetaContract,
        erc721MetaContractAddress
      );
      const sellerAddress = Store.getters["wallet/getAddress"];

      // let sellAddressNonce = await callContract(
      //   erc721MetaContract,
      //   "getNonce",
      //   sellerAddress
      // );

      let sellAddressNonce = new Date().getTime();

      let signTransaction = await this.getTransactionData(
        sellerAddress,
        prKey,
        sellAddressNonce,
        ERC721ABI[12],
        domainData,
        [marketContractAddress, tokenId]
      );

      metaTxStorage.push({
        functionSignature: signTransaction.functionSignature,
        sigR: signTransaction.r,
        sigS: signTransaction.s,
        sigV: signTransaction.v,
        nonce: sellAddressNonce,
      });

      domainData = await this.getDomainDataByContract(
        marketContract,
        marketContractAddress
      );
      // sellAddressNonce = await callContract(
      //   marketContract,
      //   "getNonce",
      //   sellerAddress
      // );

      sellAddressNonce = new Date().getTime();

      signTransaction = await this.getTransactionData(
        sellerAddress,
        prKey,
        sellAddressNonce,
        MarketABI[19],
        domainData,
        [tokenId, formatPrice(proposalPrice), subContractAddress]
      );

      metaTxStorage.push({
        functionSignature: signTransaction.functionSignature,
        sigR: signTransaction.r,
        sigS: signTransaction.s,
        sigV: signTransaction.v,
        nonce: sellAddressNonce,
      });

      return [null, metaTxStorage];
    } catch (e) {
      return [e, null];
    }
  },

  async buyCsh(proposalPrice, fee, tokenId) {
    try {
      const metaTxStorage = [];
      const subContract = new web3.eth.Contract(SubAbi, subContractAddress)
        .methods;
      const marketContract = new web3.eth.Contract(
        MarketABI,
        marketContractAddress
      ).methods;
      const buyerAddress = Store.getters["wallet/getAddress"];

      let _nonce = await callContract(subContract, "nonces", buyerAddress);
      const _maxDeadline = 9999999999;
      const _name = await callContract(subContract, "name");
      const _version = "1";
      let _chainId = await callContract(marketContract, "getChainId");
      _chainId = "0x" + (+_chainId).toString(16).padStart(64, "0");

      let message = {
        owner: buyerAddress,
        spender: marketContractAddress,
        value: formatPrice(proposalPrice),
        nonce: parseInt(_nonce),
        deadline: _maxDeadline,
      };

      let domain = {
        name: _name,
        version: _version,
        chainId: _chainId,
        verifyingContract: subContractAddress,
      };

      let signTransaction = await this.getTransactionDataPermit(
        prKey,
        domain,
        message
      );
      metaTxStorage.push({
        functionSignature: "0x",
        sigR: signTransaction.r,
        sigS: signTransaction.s,
        sigV: signTransaction.v,
        nonce: 0,
      });

      _nonce = +(await callContract(subContract, "nonces", buyerAddress)) + 1;

      message = {
        owner: buyerAddress,
        spender: paymasterContractAddress,
        value: formatPrice(fee),
        nonce: parseInt(_nonce),
        deadline: _maxDeadline,
      };

      signTransaction = await this.getTransactionDataPermit(
        prKey,
        domain,
        message
      );

      metaTxStorage.push({
        functionSignature: "0x",
        sigR: signTransaction.r,
        sigS: signTransaction.s,
        sigV: signTransaction.v,
        nonce: 0,
      });

      let domainData = await this.getDomainDataByContract(
        marketContract,
        marketContractAddress
      );
      // let buyAddressNonce = await callContract(
      //   marketContract,
      //   "getNonce",
      //   buyerAddress
      // );

      let buyAddressNonce = new Date().getTime();

      signTransaction = await this.getTransactionData(
        buyerAddress,
        prKey,
        buyAddressNonce,
        MarketABI[8],
        domainData,
        [tokenId]
      );

      metaTxStorage.push({
        functionSignature: signTransaction.functionSignature,
        sigR: signTransaction.r,
        sigS: signTransaction.s,
        sigV: signTransaction.v,
        nonce: buyAddressNonce,
      });

      return [null, metaTxStorage];
    } catch (e) {
      return [e, null];
    }
  },

  async sellSubToSystem(subAmount) {
    try {
      const sellAddress = Store.getters["wallet/getAddress"];
      const subContract = new web3.eth.Contract(SubAbi, subContractAddress)
        .methods;
      const marketContract = new web3.eth.Contract(
        MarketABI,
        marketContractAddress
      ).methods;
      let _nonce = await callContract(subContract, "nonces", sellAddress);
      const _maxDeadline = 9999999999;
      const _name = await callContract(subContract, "name");
      const _version = "1";
      let _chainId = await callContract(marketContract, "getChainId");
      _chainId = "0x" + (+_chainId).toString(16).padStart(64, "0");

      let message = {
        owner: sellAddress,
        spender: paymasterContractAddress,
        value: formatPrice(subAmount),
        nonce: parseInt(_nonce),
        deadline: _maxDeadline,
      };

      let domain = {
        name: _name,
        version: _version,
        chainId: _chainId,
        verifyingContract: subContractAddress,
      };

      let signTransaction = await this.getTransactionDataPermit(
        prKey,
        domain,
        message
      );
      return [
        null,
        {
          functionSignature: "0x",
          sigR: signTransaction.r,
          sigS: signTransaction.s,
          sigV: signTransaction.v,
          nonce: 0,
        },
      ];
    } catch (e) {
      return [e, null];
    }
  },

  async getTransactionDataPermit(prKey, domainData, message) {
    const dataToSign = {
      types: {
        EIP712Domain: [
          { name: "name", type: "string" },
          { name: "version", type: "string" },
          { name: "chainId", type: "uint256" },
          { name: "verifyingContract", type: "address" },
        ],
        Permit: [
          { name: "owner", type: "address" },
          { name: "spender", type: "address" },
          { name: "value", type: "uint256" },
          { name: "nonce", type: "uint256" },
          { name: "deadline", type: "uint256" },
        ],
      },

      domain: domainData,
      primaryType: "Permit",
      message: message,
    };

    const signature = sigUtil.signTypedMessage(ethUtils.toBuffer(prKey), {
      data: dataToSign,
    });

    let r = signature.slice(0, 66);
    let s = "0x".concat(signature.slice(66, 130));
    let v = "0x".concat(signature.slice(130, 132));
    v = parseInt(v);
    if (![27, 28].includes(v)) v += 27;

    return {
      r,
      s,
      v,
    };
  },

  async getTransactionData(address, prKey, nonce, abi, domainData, params) {
    const functionSignature = web3.eth.abi.encodeFunctionCall(abi, params);
    let message = {};
    message.nonce = parseInt(nonce);
    message.from = address;
    message.functionSignature = functionSignature;
    const dataToSign = {
      types: {
        EIP712Domain: [
          {
            name: "name",
            type: "string",
          },
          {
            name: "version",
            type: "string",
          },
          {
            name: "verifyingContract",
            type: "address",
          },
          {
            name: "salt",
            type: "bytes32",
          },
        ],
        MetaTransaction: [
          {
            name: "nonce",
            type: "uint256",
          },
          {
            name: "from",
            type: "address",
          },
          {
            name: "functionSignature",
            type: "bytes",
          },
        ],
      },
      domain: domainData,
      primaryType: "MetaTransaction",
      message: message,
    };

    const signature = sigUtil.signTypedData(ethUtils.toBuffer(prKey), {
      data: dataToSign,
    });

    let r = signature.slice(0, 66);
    let s = "0x".concat(signature.slice(66, 130));
    let v = "0x".concat(signature.slice(130, 132));
    v = parseInt(v);
    if (![27, 28].includes(v)) v += 27;

    return {
      r,
      s,
      v,
      functionSignature,
    };
  },

  async getDomainDataByContract(contract, address) {
    const chainId = await callContract(contract, "getChainId");
    return {
      name: await callContract(contract, "name"),
      version: await callContract(contract, "ERC712_VERSION"),
      verifyingContract: address,
      salt: "0x" + (+chainId).toString(16).padStart(64, "0"),
    };
  },

  async sellCshToSystem(tokenId) {
    try {
      const metaTxStorage = [];
      const erc721MetaContract = new web3.eth.Contract(
        ERC721ABI,
        erc721MetaContractAddress
      ).methods;

      let domainData = await this.getDomainDataByContract(
        erc721MetaContract,
        erc721MetaContractAddress
      );
      const sellerAddress = Store.getters["wallet/getAddress"];

      let sellAddressNonce = new Date().getTime();

      let signTransaction = await this.getTransactionData(
        sellerAddress,
        prKey,
        sellAddressNonce,
        ERC721ABI[12],
        domainData,
        [systemAddress, tokenId]
      );

      metaTxStorage.push({
        functionSignature: signTransaction.functionSignature,
        sigR: signTransaction.r,
        sigS: signTransaction.s,
        sigV: signTransaction.v,
        nonce: sellAddressNonce,
      });

      return [null, metaTxStorage];
    } catch (e) {
      return [e, null];
    }
  },

  login(message, privateKey) {
    return web3.eth.accounts.sign(message, privateKey);
  },
};
