import Vue from "vue";
import Vuex from "vuex";
import General from "@/store/modules/general";
import User from "@/store/modules/user";
import Wallet from "@/store/modules/wallet";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    general: General,
    user: User,
    wallet: Wallet,
  },
  strict: true,
});

export default store;
