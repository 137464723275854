<template>
  <div class="popup-container">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup popup-success">
        <div class="popup-swipe" v-touch:swipe.bottom="closeModal">
          <span></span>
        </div>
        <div class="popup__content">
          <div class="popup-success__img">
            <SuccessSvg />
          </div>

          <div class="popup__title">
            {{ title }}
          </div>

          <div class="popup__text">
            {{ message }}
          </div>

          <router-link
            v-if="redirectTo"
            :to="redirectTo"
            tag="button"
            class="popup__btn"
            @click.native="closeModal"
          >
            {{ $t(btnName) }}
          </router-link>
          <button v-if="!redirectTo" class="popup__btn" @click="closeModal">
            {{ btnName }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseModalMixin from "@/mixins/common/CloseModalMixin";
import SetSuccessMixin from "@/mixins/general/SetSuccessMixin";
import SuccessSvg from "../icons/SuccessSvg";

export default {
  name: "SuccessModal",
  components: { SuccessSvg },
  mixins: [CloseModalMixin, SetSuccessMixin],
  props: {
    title: {
      type: String,
      required: false,
      default: () => "Success",
    },
    message: {
      type: String,
      required: false,
      default: () => "",
    },
    redirectTo: {
      type: String,
      required: false,
      default: () => null,
    },
    btnName: {
      default: "OK",
    },
  },
  methods: {
    closeModal() {
      this.setSuccess(null);
      this.close();
    },
  },
};
</script>

<style scoped></style>
