import api from "@/api/api";

export default {
  namespaced: true,
  state: {
    wallet: JSON.parse(localStorage.getItem("wallet")) || null,
    balance: null,
    balanceToppedUp: null,
  },
  mutations: {
    setBalanceToppedUp(state, payload) {
      state.balanceToppedUp = payload;
    },
    setWallet(state, payload) {
      state.wallet = payload;
      localStorage.setItem("wallet", JSON.stringify(payload));
    },
    setBalance(state, payload) {
      state.balance = payload;
    },
  },
  actions: {
    async loadBalance({ commit }) {
      try {
        const response = await api.getBalance();
        commit("setBalance", response);
      } catch (e) {
        console.log(e);
      }
    },
  },
  getters: {
    getWallet: (state) => state.wallet,
    getBalanceIgm: (state) => (state.balance ? state.balance.igm : 0),
    getBalanceSub: (state) => (state.balance ? state.balance.sub : 0),
    getAddress: (state) => state.wallet.address,
    getBalanceToppedUp: (state) => state.balanceToppedUp,
  },
};
