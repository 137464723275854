<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M28.6667 6.53339C30.5333 4.96006 33.5467 4.96006 35.36 6.53339L39.5733 10.1334C40.3733 10.8001 41.8933 11.3601 42.96 11.3601H47.4933C50.32 11.3601 52.64 13.6801 52.64 16.5067V21.0401C52.64 22.1067 53.2 23.6001 53.8667 24.4001L57.4667 28.6134C59.04 30.4801 59.04 33.4934 57.4667 35.3067L53.8667 39.5201C53.2 40.3201 52.64 41.8134 52.64 42.8801V47.4134C52.64 50.2401 50.32 52.5601 47.4933 52.5601H42.96C41.8933 52.5601 40.4 53.1201 39.6 53.7867L35.3867 57.3867C33.52 58.9601 30.5067 58.9601 28.6933 57.3867L24.48 53.7867C23.68 53.1201 22.16 52.5601 21.12 52.5601H16.4533C13.6267 52.5601 11.3067 50.2401 11.3067 47.4134V42.8534C11.3067 41.8134 10.7733 40.2934 10.1067 39.5201L6.50668 35.2801C4.96001 33.4401 4.96001 30.4534 6.50668 28.6134L10.1067 24.3734C10.7733 23.5734 11.3067 22.0801 11.3067 21.0401V16.5334C11.3067 13.7067 13.6267 11.3867 16.4533 11.3867H21.0667C22.1333 11.3867 23.6267 10.8267 24.4267 10.1601L28.6667 6.53339Z"
      fill="#9984D4"
    />
    <path
      d="M32.0002 44.9867C30.5335 44.9867 29.3335 43.7867 29.3335 42.32C29.3335 40.8533 30.5068 39.6533 32.0002 39.6533C33.4668 39.6533 34.6668 40.8533 34.6668 42.32C34.6668 43.7867 33.4935 44.9867 32.0002 44.9867Z"
      fill="#9984D4"
    />
    <path
      d="M32 36.5867C30.9067 36.5867 30 35.6801 30 34.5867V21.6801C30 20.5867 30.9067 19.6801 32 19.6801C33.0933 19.6801 34 20.5867 34 21.6801V34.5601C34 35.6801 33.12 36.5867 32 36.5867Z"
      fill="#9984D4"
    />
  </svg>
</template>

<script>
export default {
  name: "AttentionSvg",
};
</script>

<style scoped></style>
