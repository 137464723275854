<template>
  <div class="main-wrapper">
    <transition name="slide">
      <router-view />
    </transition>

    <transition name="fade">
      <SuccessModal
        v-if="getSuccess"
        :message="getSuccess.message"
        :title="getSuccess.title"
        :redirect-to="getSuccess.redirectTo"
        :btn-name="getSuccess.btnName"
      />
    </transition>
    <transition name="fade">
      <ErrorModal
        v-if="getError"
        :message="getError.message"
        :title="getError.title"
        :redirect-to="getError.redirectTo"
      />
    </transition>
    <Preloader v-if="getLoading" />

    <transition name="pop">
      <Notification v-if="isNotification" />
    </transition>
  </div>
</template>

<script>
import Notification from "./components/utils/Notification";
import { mapActions, mapGetters, mapMutations } from "vuex";
import api from "@/api/api";
import contract from "@/api/contract";
import SuccessModal from "@/components/modals/SuccessModal";
import SetProcessingMixin from "@/mixins/general/SetProcessingMixin";
import GetAllApplicationStatesMixin from "@/mixins/general/GetAllApplicationStatesMixin";
import ErrorModal from "@/components/modals/ErrorModal";
import Preloader from "@/components/utils/Preloader";

export default {
  name: "App",
  mixins: [SetProcessingMixin, GetAllApplicationStatesMixin],
  components: {
    Preloader,
    ErrorModal,
    SuccessModal,
    Notification,
  },
  data: () => ({
    isNotification: false,
  }),

  computed: {
    ...mapGetters({
      getIsAuthorized: "user/getIsAuthorized",
    }),
  },
  watch: {
    async getIsAuthorized() {
      await this.init();
    },
  },
  methods: {
    ...mapMutations({
      setUser: "user/setUser",
    }),
    ...mapActions({
      loadBalance: "wallet/loadBalance",
    }),
    async initWeb3auth() {
      try {
        await contract.initWeb3();
      } catch (e) {
        console.log(e);
      }
    },
    async auth() {
      try {
        const response = await api.auth();
        this.setUser(response);
      } catch (e) {
        console.log(e);
      }
    },
    async init() {
      await this.initWeb3auth();
      if (this.getIsAuthorized) {
        await Promise.all([this.auth(), this.loadBalance()]);
      }
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>

<style lang="scss">
@import "assets/scss/style";
</style>
