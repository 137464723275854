import { mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations({
      setSuccess: "general/setSuccess",
      setError: "general/setError",
      setLoading: "general/setLoading",
    }),
  },
};
