<template>
  <div class="preloader">
    <div class="preloader-wrap"></div>

    <!--  <div class="preloader__text">
      {{ $t(msg) }}
    </div>-->
  </div>
</template>

<script>
export default {
  name: "Preloader",
  props: {
    msg: {
      type: String,
      default: "preloader-loading",
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/preloader/loaders";
.preloader {
  position: fixed;
  z-index: 99994;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.2);
}
.preloader-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto !important;
  @include loader02(
    $size: 33px,
    $border-size: 6px,
    $duration: 1s,
    $align: middle,
    $color: #9984d4
  );
}
</style>
