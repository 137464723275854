import Store from "@/store";
import i18n from "../../i18n";

const beforeEachGuard = async (to, from, next) => {
  if (to.name === "HomeLogin") {
    next();
  }
  let language = "en";
  let path = null;
  const lsLang = localStorage.getItem("lang");
  if (to.params.lang && ["en", "ua"].includes(to.params.lang)) {
    language = to.params.lang;
    path = to.path;
    if (!lsLang || lsLang !== to.params.lang) {
      localStorage.setItem("lang", language);
    }
  }
  if (to.params.lang && !["en", "ua"].includes(to.params.lang)) {
    if (lsLang && ["en", "ua"].includes(lsLang)) {
      language = lsLang;
      path = `/${language}/${to.path.split("/").slice(2).join("/")}`;
    }
    if (lsLang && !["en", "ua"].includes(lsLang)) {
      localStorage.setItem("lang", "en");
      path = `/en/${to.path.split("/").slice(2).join("/")}`;
    }
    if (!lsLang) {
      localStorage.setItem("lang", "en");
      path = `/en/${to.path.split("/").slice(2).join("/")}`;
    }
  }
  if (!to.params.lang) {
    if (lsLang && ["en", "ua"].includes(lsLang)) {
      language = lsLang;
      path = `/${language}/home`;
    }
    if (lsLang && !["en", "ua"].includes(lsLang)) {
      localStorage.setItem("lang", language);
      path = `/${language}/home`;
    }
    if (!lsLang) {
      localStorage.setItem("lang", "en");
      path = `/${language}/home`;
    }
  }
  await import(`@/locales/${language}.json`);
  i18n.locale = language;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!Store.getters["user/getIsAuthorized"]) {
      next({
        path: `/${language}/sign-in`,
        query: to.query,
      });
    } else {
      if (to.path !== path) {
        next({
          path,
          query: to.query,
        });
      } else {
        next();
      }
    }
  } else {
    if (to.path !== path) {
      next({
        path,
        query: to.query,
      });
    } else {
      next();
    }
  }
};

export default beforeEachGuard;
