<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M28.6667 6.53339C30.5067 4.96006 33.52 4.96006 35.3867 6.53339L39.6 10.1601C40.4 10.8534 41.8933 11.4134 42.96 11.4134H47.4933C50.32 11.4134 52.64 13.7334 52.64 16.5601V21.0934C52.64 22.1334 53.2 23.6534 53.8933 24.4534L57.52 28.6667C59.0933 30.5067 59.0933 33.5201 57.52 35.3867L53.8933 39.6001C53.2 40.4001 52.64 41.8934 52.64 42.9601V47.4934C52.64 50.3201 50.32 52.6401 47.4933 52.6401H42.96C41.92 52.6401 40.4 53.2001 39.6 53.8934L35.3867 57.5201C33.5467 59.0934 30.5333 59.0934 28.6667 57.5201L24.4533 53.8934C23.6533 53.2001 22.16 52.6401 21.0933 52.6401H16.48C13.6533 52.6401 11.3333 50.3201 11.3333 47.4934V42.9334C11.3333 41.8934 10.7733 40.4001 10.1067 39.6001L6.50668 35.3601C4.96001 33.5201 4.96001 30.5334 6.50668 28.6934L10.1067 24.4534C10.7733 23.6534 11.3333 22.1601 11.3333 21.1201V16.5334C11.3333 13.7067 13.6533 11.3867 16.48 11.3867H21.0933C22.1333 11.3867 23.6533 10.8267 24.4533 10.1334L28.6667 6.53339Z"
      fill="#9984D4"
    />
    <path
      d="M28.7733 40.4533C28.24 40.4533 27.7333 40.24 27.36 39.8666L20.9067 33.4133C20.1333 32.64 20.1333 31.36 20.9067 30.5866C21.68 29.8133 22.96 29.8133 23.7333 30.5866L28.7733 35.6266L40.24 24.16C41.0133 23.3866 42.2933 23.3866 43.0667 24.16C43.84 24.9333 43.84 26.2133 43.0667 26.9866L30.1867 39.8666C29.8133 40.24 29.3067 40.4533 28.7733 40.4533Z"
      fill="#9984D4"
    />
  </svg>
</template>

<script>
export default {
  name: "SuccessSvg",
};
</script>

<style scoped></style>
