export default {
  namespaced: true,
  state: {
    error: null,
    success: false,
    loading: false,
  },
  mutations: {
    setError(state, error) {
      state.error = error;
    },
    setSuccess(state, success) {
      state.success = success;
    },
    setLoading(state, status) {
      state.loading = status;
    },
  },
  getters: {
    getError: (state) => state.error,
    getSuccess: (state) => state.success,
    getLoading: (state) => state.loading,
  },
};
