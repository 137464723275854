export default {
  namespaced: true,
  state: {
    isAuthorized: !!localStorage.getItem("token"),
    user: null,
  },
  mutations: {
    setToken(state, payload) {
      state.isAuthorized = true;
      localStorage.setItem("token", payload);
    },
    setUser(state, payload) {
      state.user = payload;
    },
    unsetUser(state) {
      state.isAuthorized = false;
      state.user = null;
      localStorage.removeItem("token");
    },
  },
  getters: {
    getIsAuthorized: (state) => state.isAuthorized,
    getUserId: (state) => state.user?.id,
  },
};
