<template>
  <div class="notification">
    <!--    <div class="notification__icon">
      <img src="@/assets/img/game-center-logo.png" alt="" />
    </div>-->

    <div class="notification__text">
      {{ msg }}
    </div>

    <div class="notification__caption">
      {{ subMsg }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Notification",
  props: {
    msg: {
      default: "",
    },
    subMsg: {
      default: "",
    },
  },
};
</script>

<style scoped></style>
