import AuthUserGuard from "./guards/AuthUserGuard";

export default [
  {
    path: "/",
    name: "Root",
    beforeEnter: (to, from, next) => {
      next({
        path: "/en",
      });
    },
  },
  {
    path: "/auth/token/:token",
    name: "HomeLogin",
    beforeEnter: AuthUserGuard,
  },
  {
    path: "/:lang",
    component: () =>
      import(/*webpackChunkName: "lang-stack"*/ "@/views/LangStack.vue"),
    children: [
      {
        path: "",
        redirect: "home",
        name: "RootStack",
        component: () =>
          import(/*webpackChunkName: "root-stack"*/ "@/views/RootStack.vue"),
        children: [
          {
            path: "home",
            name: "Home",
            component: () =>
              import(/*webpackChunkName: "home"*/ "@/views/home/Home.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "shoppie",
            name: "Shoppie",
            component: () =>
              import(/*webpackChunkName: "home"*/ "@/views/home/Shoppie.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "events",
            name: "Events",
            component: () =>
              import(/*webpackChunkName: "events"*/ "@/views/home/Events.vue"),
          },
          {
            path: "profile",
            name: "Profile",
            component: () =>
              import(
                /*webpackChunkName: "profile"*/ "@/views/home/Profile.vue"
              ),
          },
          // {
          //   path: "market",
          //   name: "Market",
          //   component: () =>
          //     import(/*webpackChunkName: "market"*/ "@/views/home/Market.vue"),
          // },
          {
            path: "market",
            name: "Market",
            component: () =>
              import(
                /*webpackChunkName: "market-main"*/ "@/views/market/MarketMain.vue"
              ),
          },
          {
            path: "swap-coins",
            name: "SwapCoins",
            component: () =>
              import(
                /*webpackChunkName: "swap-coins"*/ "@/views/market/SwapCoins.vue"
              ),
          },
          {
            path: "market-character",
            name: "MarketCharacter",
            component: () =>
              import(
                /*webpackChunkName: "market-character"*/ "@/views/market/MarketCharacter.vue"
              ),
          },
          {
            path: "cryptoshoppie/sell/:id",
            props: true,
            name: "CharacterSell",
            component: () =>
              import(
                /*webpackChunkName: "sell-char"*/ "@/views/market/SellChar.vue"
              ),
          },
          {
            path: "cryptoshoppie/sell-system/:id",
            props: true,
            name: "SellSystem",
            component: () =>
              import(
                /*webpackChunkName: "sell-system"*/ "@/views/market/SellSystem.vue"
              ),
          },
          {
            path: "cryptoshoppie/buy/:id",
            props: true,
            name: "CharacterBuy",
            component: () =>
              import(
                /*webpackChunkName: "buy-char"*/ "@/views/market/BuyChar.vue"
              ),
          },
          {
            path: "breeding",
            name: "Breeding",
            component: () =>
              import(
                /*webpackChunkName: "breeding"*/ "@/views/home/Breeding.vue"
              ),
          },
          {
            path: "rules",
            name: "Rules",
            component: () =>
              import(/*webpackChunkName: "rules"*/ "@/views/RulesPage.vue"),
          },
          {
            path: "cryptoshoppie/:id",
            name: "CryptoshoppiePage",
            component: () =>
              import(
                /*webpackChunkName: "csh-page"*/ "@/views/CryptoshoppiePage.vue"
              ),
            props: true,
          },
          {
            path: "cryptoshoppie/:id/upgrade",
            props: true,
            name: "Upgrade",
            component: () =>
              import(/*webpackChunkName: "upgrade"*/ "@/views/Upgrade.vue"),
          },
          {
            path: "mates-list",
            name: "Mates",
            component: () =>
              import(/*webpackChunkName: "mates"*/ "@/views/Mates.vue"),
          },
          {
            path: "mini-game",
            name: "Game",
            component: () =>
              import(
                /*webpackChunkName: "mini-game"*/ "@/views/game/MiniGame.vue"
              ),
          },
          {
            path: "demo-game",
            name: "DemoGame",
            component: () =>
              import(
                /*webpackChunkName: "demo-game"*/ "@/views/game/DemoGame.vue"
              ),
          },
        ],
      },
      {
        path: "scan",
        name: "QrReader",
        component: () =>
          import(/*webpackChunkName: "qr-reader"*/ "@/views/QrReader.vue"),
      },
      {
        path: "choice",
        name: "ChoicePage",
        component: () =>
          import(/*webpackChunkName: "choice"*/ "@/views/ChoicePage.vue"),
      },
      {
        path: "sign-in",
        name: "SignIn",
        component: () =>
          import(/*webpackChunkName: "sign-in"*/ "@/views/SignIn.vue"),
      },
    ],
  },
];
