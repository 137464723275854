import Store from "@/store";
import api from "@/api/api";

const AuthUserGuard = async (to, from, next) => {
  const token = to.params.token;
  const lang = localStorage.getItem("lang");
  if (token) {
    const qrCode = localStorage.getItem("qrCode");
    Store.commit("user/setToken", token);
    try {
      const wallet = await api.createWallet();
      localStorage.setItem("wallet", JSON.parse(wallet));
    } catch (e) {
      console.log(e);
    }
    next({
      path: `/${lang ? lang : "en"}/home`,
      query: qrCode ? { code: qrCode } : {},
    });
  } else {
    next({
      path: `${lang ? lang : "en"}/sign-in`,
    });
  }
};

export default AuthUserGuard;
