import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store";
import "./registerServiceWorker";
import i18n from "./i18n";
import Vue2TouchEvents from "vue2-touch-events";
import ScrollLoader from "vue-scroll-loader";
import vueAwesomeCountdown from "vue-awesome-countdown";

Vue.use(vueAwesomeCountdown, "vac");
Vue.use(ScrollLoader);
Vue.use(Vue2TouchEvents);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
