import Vue from "vue";
import Router from "vue-router";
import routes from "@/router/routes";
import BeforeEachGuard from "@/router/guards/BeforeEachGuard";

Vue.use(Router);

const router = new Router({
  routes,
  mode: "history",
  scrollBehavior: function (to) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(BeforeEachGuard);

export default router;
