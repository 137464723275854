import { render, staticRenderFns } from "./CoinGoldSvg.vue?vue&type=template&id=2eb63d80&scoped=true&"
import script from "./CoinGoldSvg.vue?vue&type=script&lang=js&"
export * from "./CoinGoldSvg.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2eb63d80",
  null
  
)

export default component.exports