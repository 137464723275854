import http from "@/utils/http";

const authEndpoints = {
  AUTH: "/auth",
  LOCAL_SIGNUP: "/auth/signup/local",
  LOCAL_LOGIN: "/auth/login/local",
};

const userEndpoints = {
  BY_ID: (id) => `/users/${id}`,
  WALLET: "/users/wallet",
  BALANCE: "/users/me/wallet",
};

const cshEndpoints = {
  CRYPTOSHOPPIES: "/cryptoshoppies",
  GET_CRYPTOSHOPPIES_BY_ID: (id) => `/cryptoshoppies/${id}`,
  GET_CRYPTOSHOPPIES_CHILDREN_BY_ID: (id) => `/cryptoshoppies/${id}/children`,
  BUY_SKILL: (id) => `/cryptoshoppies/${id}/upgrades`,
  UPGRADE: (id, skill) => `/cryptoshoppies/${id}/skills/${skill}`,
  UPGRADE_CSH_COMMUNITY_STATUS: (id) => `/cryptoshoppies/${id}/community`,
};

const currencyCards = {
  VALIDATE: (code) => `/currency-cards/${code}`,
  USE_CARD_AS_CURRENCY: (code) => `/currency-cards/${code}`,
  USE_CARD_AS_CHARACTER: (code) => `/currency-cards/${code}/character`,
};

const breedingEndpoints = {
  COMMUNITY: "/breeding/community",
  OWN: "/breeding/own",
  BREEDING: (firstParentId, secondParentId) =>
    `/breeding/${firstParentId}/${secondParentId}`,
};

const eventsController = {
  GET_MAIN_EVENT: "/events",
};

const spaceInvaders = {
  SPACE_INVADERS: "/mini-games/space-invaders",
  DEMO: "/mini-games/space-invaders/demo",
  FINISH: "/mini-games/space-invaders/finish",
};

const market = {
  CSH_ON_MARKET: "/market/cryptoshoppies",
  CSH_ON_MARKET_OWN: "/market/cryptoshoppies/own",
  PUT_FOR_SALE: "/market",
  BUY_OR_REMOVE: (itemId) => `/market/${itemId}`,
  SELL_TO_SYSTEM: (cshId) => `/market/cryptoshoppies/${cshId}/system`,
  GET_RATES: "/market/rates",
  SWAP: "/market/transactions/subigm",
};

const finishCurrentGame = async () => {
  const response = await http.put(spaceInvaders.FINISH, null, {
    params: {
      isPublic: false,
    },
  });
  return response.data;
};

const startDemoGame = async (payload) => {
  const response = await http.post(spaceInvaders.DEMO, payload, {
    params: {
      isPublic: false,
    },
  });
  return response.data;
};

const startGame = async (payload) => {
  const response = await http.post(spaceInvaders.SPACE_INVADERS, payload, {
    params: {
      isPublic: false,
    },
  });
  return response.data;
};

const getSpaceInvadersInfo = async () => {
  const response = await http.get(spaceInvaders.SPACE_INVADERS, {
    params: {
      isPublic: false,
    },
  });
  return response.data;
};

const getMainEvent = async () => {
  const response = await http.get(eventsController.GET_MAIN_EVENT, {
    params: {
      isPublic: false,
    },
  });
  return response.data;
};

const startBreeding = async (payload) => {
  const response = await http.post(
    breedingEndpoints.BREEDING(payload.firstParentId, payload.secondParentId),
    { sig: payload.sig },
    {
      params: {
        isPublic: false,
      },
    }
  );
  return response.data;
};

const upgradeCshCommunityStatus = async (payload) => {
  const response = await http.put(
    cshEndpoints.UPGRADE_CSH_COMMUNITY_STATUS(payload.id),
    {
      breedingAmount: payload.breedingAmount,
    },
    {
      params: {
        isPublic: false,
      },
    }
  );
  return response.data;
};

const getCshForCommunityBreeding = async (payload) => {
  const response = await http.get(breedingEndpoints.COMMUNITY, {
    params: {
      ...payload,
      isPublic: false,
    },
  });
  return response.data;
};

const getCshForOwnBreeding = async (payload) => {
  const response = await http.get(breedingEndpoints.OWN, {
    params: {
      ...payload,
      isPublic: false,
    },
  });
  return response.data;
};

const createWallet = async () => {
  const response = await http.post(userEndpoints.WALLET, null, {
    params: {
      isPublic: false,
    },
  });
  return response.data;
};

const getBalance = async () => {
  const response = await http.get(userEndpoints.BALANCE, {
    params: {
      isPublic: false,
    },
  });
  return response.data;
};

const getUserById = async (payload) => {
  const response = await http.get(userEndpoints.BY_ID(payload), {
    params: {
      isPublic: false,
    },
  });
  return response.data;
};

const useCurrencyCardAsCurrency = async (payload) => {
  const response = await http.put(
    currencyCards.USE_CARD_AS_CURRENCY(payload),
    null,
    {
      params: {
        isPublic: false,
      },
    }
  );
  return response.data;
};

const useCurrencyCardAsCharacter = async (payload) => {
  const response = await http.put(
    currencyCards.USE_CARD_AS_CHARACTER(payload),
    null,
    {
      params: {
        isPublic: false,
      },
    }
  );
  return response.data;
};

const validateCurrencyCard = async (payload) => {
  const response = await http.get(currencyCards.VALIDATE(payload), {
    params: {
      isPublic: false,
    },
  });
  return response.data;
};

const auth = async () => {
  const response = await http.get(authEndpoints.AUTH, {
    params: { isPublic: false },
  });
  return response.data;
};

const signUpLocal = async () => {
  const response = await http.post(authEndpoints.LOCAL_SIGNUP, null, {
    params: { isPublic: true },
  });
  return response.data;
};

const loginLocal = async (payload) => {
  const response = await http.post(authEndpoints.LOCAL_LOGIN, payload, {
    params: { isPublic: true },
  });
  return response.data;
};

const getUserCryptoshoppies = async (payload) => {
  const response = await http.get(cshEndpoints.CRYPTOSHOPPIES, {
    params: {
      ...payload,
      isPublic: false,
    },
  });
  return response.data;
};

const getCryptoshoppiesById = async (payload) => {
  const response = await http.get(
    cshEndpoints.GET_CRYPTOSHOPPIES_BY_ID(payload),
    {
      params: {
        isPublic: false,
      },
    }
  );
  return response.data;
};

const getCryptoshoppiesChildrenById = async (payload) => {
  const response = await http.get(
    cshEndpoints.GET_CRYPTOSHOPPIES_CHILDREN_BY_ID(payload),
    {
      params: {
        isPublic: false,
      },
    }
  );
  return response.data;
};

const createTestCryptoshoppie = async (payload) => {
  const response = await http.post(cshEndpoints.CRYPTOSHOPPIES, null, {
    params: {
      ...payload,
      isPublic: false,
    },
  });
  return response.data;
};

const buySkillPoint = async (body, id) => {
  const response = await http.post(cshEndpoints.BUY_SKILL(id), body, {
    params: {
      isPublic: false,
    },
  });
  return response.data;
};

const upgradeCsh = async (id, skill) => {
  const response = await http.put(cshEndpoints.UPGRADE(id, skill), null, {
    params: {
      isPublic: false,
    },
  });
  return response.data;
};

const getCshOnMarket = async (payload) => {
  const response = await http.get(market.CSH_ON_MARKET, {
    params: {
      ...payload,
      isPublic: false,
    },
  });
  return response.data;
};

const getOwnCshOnMarket = async (payload) => {
  const response = await http.get(market.CSH_ON_MARKET_OWN, {
    params: {
      ...payload,
      isPublic: false,
    },
  });
  return response.data;
};

const putCshForSale = async (payload) => {
  const response = await http.post(market.PUT_FOR_SALE, payload, {
    params: {
      isPublic: false,
    },
  });
  return response.data;
};

const buyCsh = async (payload) => {
  const response = await http.put(
    market.BUY_OR_REMOVE(payload.itemId),
    {
      sig: payload.signatures,
    },
    {
      params: {
        isPublic: false,
      },
    }
  );
  return response.data;
};

const removeCshFromMarket = async (payload) => {
  const response = await http.delete(market.BUY_OR_REMOVE(payload), {
    params: {
      isPublic: false,
    },
  });
  return response.data;
};

const sellCshToSystem = async (payload) => {
  const response = await http.post(
    market.SELL_TO_SYSTEM(payload.cshId),
    payload.sig,
    {
      params: {
        isPublic: false,
      },
    }
  );
  return response.data;
};

const getRates = async () => {
  const response = await http.get(market.GET_RATES, {
    params: {
      isPublic: false,
    },
  });
  return response.data;
};

const makeCoinSwap = async (payload) => {
  const response = await http.post(market.SWAP, payload, {
    params: {
      isPublic: false,
    },
  });
  return response.data;
};

export default {
  auth,
  signUpLocal,
  loginLocal,
  getUserCryptoshoppies,
  createTestCryptoshoppie,
  getCryptoshoppiesById,
  getCryptoshoppiesChildrenById,
  getUserById,
  validateCurrencyCard,
  useCurrencyCardAsCurrency,
  useCurrencyCardAsCharacter,
  buySkillPoint,
  upgradeCsh,
  createWallet,
  getBalance,
  getCshForCommunityBreeding,
  getCshForOwnBreeding,
  upgradeCshCommunityStatus,
  startBreeding,
  getMainEvent,
  getSpaceInvadersInfo,
  startDemoGame,
  finishCurrentGame,
  startGame,
  getCshOnMarket,
  getOwnCshOnMarket,
  putCshForSale,
  buyCsh,
  removeCshFromMarket,
  sellCshToSystem,
  getRates,
  makeCoinSwap,
};
