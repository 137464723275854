<template>
  <div class="popup-container">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup popup-success">
        <div class="popup-swipe" v-touch:swipe.bottom="closeModal">
          <span></span>
        </div>
        <div class="popup__content">
          <div class="popup-success__img">
            <AttentionSvg />
          </div>

          <div class="popup__title">
            {{ title }}
          </div>

          <div class="popup__text">
            {{ message }}
          </div>

          <div v-if="!fundsError">
            <router-link
              v-if="redirectTo"
              :to="redirectTo"
              tag="button"
              class="popup__btn"
              @click.native="closeModal"
            >
              {{ $t(btnName) }}
            </router-link>
            <button v-if="!redirectTo" class="popup__btn" @click="closeModal">
              OK
            </button>
          </div>

          <div class="popup-btns" v-if="fundsError">
            <button tag="button" class="popup__btn" @click="closeModal">
              {{ $t("close-btn") }}
            </button>
            <router-link
              tag="button"
              to="buy-coins"
              class="popup__btn"
              @click.native="closeModal"
            >
              <CoinGoldSvg />
              {{ $t("buy-btn") }} SUB
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseModalMixin from "@/mixins/common/CloseModalMixin";
import setErrorMixin from "@/mixins/general/SetErrorMixin";
import AttentionSvg from "../icons/AttentionSvg";
import CoinGoldSvg from "../icons/CoinGoldSvg";

export default {
  name: "ErrorModal",
  components: { CoinGoldSvg, AttentionSvg },
  mixins: [CloseModalMixin, setErrorMixin],
  props: {
    title: {
      type: String,
      required: false,
      default: () => "Error",
    },
    message: {
      type: String,
      required: false,
      default: () => "",
    },
    redirectTo: {
      type: String,
      required: false,
      default: () => null,
    },
    fundsError: {
      default: false,
    },
    btnName: {
      default: "popup-back-btn",
    },
  },
  methods: {
    closeModal() {
      this.setError(null);
      this.close();
    },
  },
};
</script>

<style scoped></style>
